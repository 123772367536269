.section{
    @include media-breakpoint-up(md) {  margin-top: 5em ;   margin-bottom: 5em ;}
    margin-top: 3em ;
    margin-bottom: 3em ;

    .section-text-content{
        
    }
    .section-img{
        border-radius: 16px;
        img{
            border-radius: 16px;
        }
    }
}