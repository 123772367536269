.service-item {

    background: linear-gradient(180deg, #F86259 0%, #F63B2F 100%);
    border-radius: 16px;
    padding: 50px 25px;
    color: $bg-color;
    font-weight: 500;
    @include font-24;
    text-align: center;
    height: 100%;
    position: relative;

    @include media-breakpoint-up(md) {
        width: 100%;
    }

    width: 80%;
    margin: 0 auto;
    // margin-inline: 2em;

    &.gray {
        background: $gray-service-bg;
        color: $black-color;

        &::after {
            border: 2px solid $red-color;
        }

        @include media-breakpoint-up(lg) {
            &:hover {
                background: linear-gradient(180deg, #EDF2F7 0%, #DAE0E8 100%);
            }
        }
    }

    .service-icon {
        margin-bottom: 32px;
    }



    &::after {
        content: "";
        border: 2px solid #CBD5E0;
        border-radius: 16px;
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: -5.59%;
        left: 4.44%;
        right: -4.44%;
        top: 5.59%;
        z-index: -1;
        display: block;
        opacity: 0;
        transition: all 0.3s ease 0s;
    }

    @include media-breakpoint-up(lg) {
        &:hover {

            background: linear-gradient(180deg, #FC2D21 0%, #DC3227 100%);

            &::after {
                // display: block;
                opacity: 1;
            }
        }
    }

}

.service-info-item {
    border-radius: 12px;
    // height: 100%;
    position: relative;
    width: fit-content;
    transition: all 0.5s ease 0s;
    $text-bottom: 7.92%;
    $padding-hover : 3.8%;
    margin: 0 auto;

    @include media-breakpoint-up(md) {
        width: 100%;
    }

    width: 80%;

    span {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        text-align: center;
        color: $bg-color;
        font-weight: 500;
        @include font-medium;
        @include font-24;
        bottom: $text-bottom;
        transition: all 0.3s ease 0s;
        z-index: 2;
        padding: 0 8px;
    }

    &::after {
        content: '';
        width: 100%;
        height: inherit;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(45, 10, 10, 0.4) 87.56%);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 12px;
        transition: all 0.3s ease 0s;

        opacity: 0;

        z-index: 1;

    }

    @include media-breakpoint-up(lg) {
        &:hover {
            // padding-top: $padding-hover;
            transform: translate3d(0px, $padding-hover, 0);

            span {
                // bottom: $text-bottom - $padding-hover ;
            }

            &::after {

                top: 3.1%;
                opacity: 1;
            }

        }
    }
}

.service-list-ul {
    li {
        color: $black-color;
        font-weight: 400;
        @include font-24;
        margin-bottom: 1.4em;


    }

    &.hor {
        li {
            float: left;
        }
    }
}