@mixin font-18 {
  font-size: 1em  !important;
}

@mixin font-20 {
  font-size: 1.1em !important;
}
@mixin font-24 {
  font-size: 1.3em;
}
@mixin font-32 {
  @include media-breakpoint-up(lg) {   font-size: 1.8em; }
  font-size: 1.6em;
}

@mixin font-40 {
  font-size: 2.5em;
}
@mixin font-56 {
  @include media-breakpoint-up(lg) {   font-size: 3.2em; }
  font-size: 2.2em;
}







// @mixin font-20 {
//   font-size: 1.3em !important;
// }

// @mixin font-18 {
//   font-size: 1.1em  !important;
// }


// @mixin font-56 {
//   font-size: 3.5em;
// }

// @mixin font-40 {
//   font-size: 2.5em;
// }
// @mixin font-32 {
//   font-size: 2em;
// }
// @mixin font-24 {
//   font-size: 1.5em;
// }

@mixin font-medium {
  font-family: 'Inter-Medium',sans-serif;
  font-weight: 500;
}
@mixin font-Bold {
  font-family: 'Inter-SemiBold',sans-serif;
  font-weight: 600;
}