.btn-rasana {

    border: 1.5px solid $red-color;
    border-radius: 8px;
    @include media-breakpoint-up(lg) {    padding: 16px 48px; }
    padding: 10px 38px;

    color: $red-color !important;
    @include font-20;

    &:hover {
        background-color: $red-color  !important;
        color: $bg-color  !important;
    }
}

.nav-link {
    position: relative;
    font-weight: 400 !important;
    color: $black-color !important;
    // margin: 0 35px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    @include font-20;
    width: fit-content;
    margin: 0 auto;

    &.active ,
    &:hover {
       
            font-weight: 600 !important;
        
        color: $red-color !important;

        &::before {
            width: 100%;
            left: 0;
        }


    }

    &::before {
        content: "";
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
        width: 0;
        height: 2px;
        background: $red-color;
        position: absolute;
        bottom: 0;
        left: 50%;
        border-radius: 4px;
        right: 0;
    }


}

.section-title {

    font-weight: 600;
    @include font-32;
    color: $red-color;
    margin-bottom: 24px;

}

.section-text {
    font-weight: 500;
    @include font-medium;
    @include font-24;
    @include media-breakpoint-up(lg) {  text-align: justify; }
    text-align: unset;
    color: $black-color;

    span {
        font-weight: 600;
    }
}

.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

@media (min-width: 1700px){
    .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: 1630px;
    }
}


ul{
    // padding-left: 3em !important;
}

.img34{
    aspect-ratio: 800 / 600;
}

.img56{
    aspect-ratio: 400 / 480;
}

a {
    color: unset !important;
    text-decoration: unset !important;
}

html {
    scroll-behavior: smooth;
  }