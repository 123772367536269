.header {


    .inner-header {
        // @include media-breakpoint-up(sm) { ... }
        // @include media-breakpoint-up(md) { ... }
        @include media-breakpoint-up(xl) {  min-height: 400px; }
        // @include media-breakpoint-up(xl) { ... }
        // @include media-breakpoint-up(xxl) { ... }
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 200px; 
        display: flex;
        justify-content: center;
        align-items: center;

        .header-text {
            font-weight: 500;
            @include font-medium;
            @include font-32;
            color: $bg-color;
            text-align: center;
            span{
                font-weight: 600;
                @include font-Bold;
            }
        }

        .header-title {
            font-weight: 600;
            @include font-56;
            color: $bg-color;
            position: relative;
            z-index: 1;

            &.borderd {

                &::after,
                &::before {
                    content: "";
                    width: 24px;
                    height: 22px;
                    @include media-breakpoint-up(lg) {         border: 5px solid #F63B2F; }
                    border: 3px solid #F63B2F;
                    position: absolute;
                    border-radius: 5px;
                }

                &::after {

                    border-top: 0;
                    border-left: 0;

                    bottom: -10%;
                    right: -6.52%;
                    border-radius: 0px 0px 5px 0px;
                }

                &::before {

                    border-radius: 5px 0px 0px 0px;
                    border-bottom: 0;
                    border-right: 0;
                    top: -10%;
                    left: -6.52%;
                }
            }
        }


    }

    &.home-header {
        .inner-header {
            @include media-breakpoint-up(xl) {    min-height: 600px; }
            min-height: 200px;
        }

    }

    &.header-2 {
        .inner-header {
            min-height: 480px;
            padding: 2em 0;
        }

    }
}