.footer{
    background-color: $gray-color;
    padding-top: 58px;
    margin-top: 6em;
   .footer-nav{
    column-gap: 48px;
    margin-top: 56px;
    margin-bottom: 40px;
   }

   .footer-hr{
    color: $footer-hr-color;
    width: 100%;
    height: 1px;
    opacity: 1;
    margin: 0;
   }
}
footer{
    background-color: $gray-color;
    padding-top: 32px;
    padding-bottom: 48px;

    .rights-footer{
        color: $footer-rights-color;
        @include font-18;
        font-weight: 500;
      @include font-medium;
        margin: 0;
    }
}