@import "bootstrap";

@font-face {
    font-family: 'Inter';
    src: url("../fonts/Inter-Regular.ttf");
    font-display: swap;
}

@font-face {
    font-family: 'Inter-Medium';
    src: url("../fonts/Inter-Medium.ttf");
    font-display: swap;
}


@font-face {
    font-family: 'Inter-SemiBold';
    src: url("../fonts/Inter-SemiBold.ttf");
    font-display: swap;
}

@font-face {
    font-family: 'Inter-Bold';
    src: url("../fonts/Inter-Bold.ttf");
    font-display: swap;
}



$bg-color:#FFFFFF;
$red-color:#F63B2F;
$black-color:#292C2E;
$gray-color:#F3F3F3;
$footer-rights-color:#B4B6B8;
$footer-hr-color:#DFE1E2;
$gray-service-bg:#EDF2F7;

body{
    font-family: 'Inter', sans-serif;
    background-color:$bg-color;
}

@import "./rasana/mixin";
@import "./rasana/core";
@import "./rasana/nav";
@import "./rasana/header";
@import "./rasana/section";
@import "./rasana/services";
@import "./rasana/footer";

@import "./rasana/contact";

