.contact-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .contact-icon {
        margin-bottom: 28px;
    }

    span {

        &:first-of-type{
            @include font-medium;
        }
        font-weight: 400;

        @include font-24;

        color: $black-color;
    }
}

.form-container {
    background: $gray-service-bg;
    border-radius: 16px;

    // padding: 50px 80px;
    padding: 1.5em 3em;
}



.form-label {
    font-weight: 600;
    @include  font-Bold;
    @include font-20;
    color: $black-color;
    margin-bottom: 1rem;

    span {
        color: $red-color;
    }

}

input,
textarea {
    padding: 16px !important;
    font-weight: 400 !important;
    @include font-18;
    color: $black-color  !important;

    background: $gray-service-bg  !important;
    border-radius: 16px !important;

    border: 2px solid $footer-rights-color  !important;
    border-radius: 8px !important;

    &:focus,
    &:not(:placeholder-shown) {
        box-shadow: none !important;
        border: 2px solid $red-color  !important;
    }

    &:active {
        border: 2px solid $red-color  !important;
    }

    &.form-check-input:checked[type=checkbox] {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e") !important;
        background-color: $red-color  !important;
    }
}

.form-check {
    display: flex;
    flex-direction: row;

    //align-items: center;

    .form-check-label {
        color: $black-color;
        font-weight: 400;
        @include font-20;
        margin-inline-start: 1em;
    }

    .form-check-input {
        border: 2px solid $red-color  !important;
        border-radius: 4px;
    }
}

.submit-btn {
    background: linear-gradient(180deg, #F86259 0%, #F63B2F 100%);
    border-radius: 8px;
    padding: 16px 48px;

    font-weight: 400;
    color: #FFFFFF;
    @include font-18;

    &:hover{
        background: linear-gradient(180deg, #FC2D21 0%, #DC3227 100%);
        color: #fff !important;
    }
}

.submit-btn:disabled{
    border-color: unset !important;
}

label.error{
    color: #F63B2F;
    font-size: 1em;
}
#res-meesage .success, #res-meesage .error{
    font-size: 1.2em;
}
#res-meesage .success{
    color: rgb(22, 172, 22);
}
#res-meesage .error{
    color: #F63B2F;
}