.rasana-nav {

  background-color: $bg-color;
  // max-height: 128px;

  padding: 1.5em;

  .navbar-nav {

    // flex: none;
    .nav-item {
      .nav-link {
      }
    }
  }

  .nav-right {
    margin: 0 35px;

    .nav-number {

      font-weight: 600;
      @include font-Bold;
      // margin-inline-end: 48px;
      // padding: 1rem 0;
      @include font-20;
    }

    .btn-rasana {
      padding: 16px 32px;
      @include font-18;
      background: #fff;
    }
  }
}

.navbar-collapse {
  background: $bg-color;
  z-index: 99999;
}

.navbar-toggler {
  border-color: $red-color;

  &.collapsed {

  }
    &[aria-expanded="false"] {
        .navbar-toggler-icon {
            --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28246, 59, 74, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
        }
    }
  &[aria-expanded="true"] {
    .navbar-toggler-icon {
      --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' stroke='rgba%28246, 59, 74, 1%29' class='bi bi-x' viewBox='0 0 16 16'%3E%3Cpath d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
    }
  }


  &:focus {
    box-shadow: none !important;
  }
}